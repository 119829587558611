<template>
  <div class="remain-problerms-detail">
    <div class="card">
      <van-row class="title">
        <van-col span="24">
          {{ customerName }}
        </van-col>
      </van-row>
      <van-row class="data">
        <van-col offset="1" span="23">
          复盘名称：{{ titleName }}
        </van-col>
      </van-row>
      <van-row class="data">
        <van-col offset="1" span="23">
          负责人：{{ fzrName }}
        </van-col>
      </van-row>
      <van-row class="data">
        <van-col offset="1" span="23">
          遗留问题：{{ problerms }}
        </van-col>
      </van-row>
    </div>
    <div v-if="list.length<=0">
      <van-empty image="error" description="没有数据"/>
    </div>
    <div v-else>
      <van-row type="flex" justify="center" align="center">
        <van-col offset="0" span="20">
          <div class="block">
            <el-timeline>
              <el-timeline-item v-if="item.status=='02'" v-for="(item,index) in list" placement="top"
                                :timestamp=item.creationTime
                                :key="index"
                                :color="item.status=='01'?'red':item.status=='02'?'#318EF5':'#0bbd87'"
                                :style="{marginBottom: index==list.length-1 ? '45px' : '0px'}">
                <div>
                  <van-row>
                    <van-col offset="4" span="10">
                      <p :style="{color:item.status=='01'?'red':item.status=='02'?'#318EF5':item.status=='00'?'darksalmon':'#0bbd87'}">
                        状态：{{
                          item.status == '03' ? '已解决' : item.status == '01' ? '持续跟进' : item.status == '00' ? '新增' : '审核中'
                        }}</p>
                    </van-col>
                    <van-col offset="1" span="4">
                      <van-button v-if="item.status=='02'" icon="good-job-o" type="info"
                                  @click="submit(item,index,'03')"/>
                    </van-col>
                    <van-col offset="1" span="4">
                      <van-button v-if="item.status=='02'" icon="clock-o" type="danger"
                                  @click="submit(item,index,'01')"/>
                    </van-col>
                  </van-row>
                  <van-row>
                    <van-col span="20">
                      <van-field type="textarea" readonly label="解决方案：" rows="5" v-model="item.scheme"></van-field>
                    </van-col>
                    <van-col offset="1" span="3">
                      <van-icon :name="item.moreBtn?'arrow-down':''" :style="{marginTop:'105px'}"
                                @click="moreInfo(item, index)"/>
                    </van-col>
                  </van-row>
                  <van-field v-show="false" type="textarea" readonly label="最新成果：" rows="5"
                             v-model="item.newResult"></van-field>
                  <van-row v-if="!item.moreBtn">
                    <van-col span="20">
                      <van-field type="textarea" :readonly="userId!='05001' && userId!='19060'" label="领导留言：" rows="3"
                                 v-model="official"></van-field>
                    </van-col>
                    <van-col offset="1" span="3">
                      <van-icon :name="item.moreBtn?'arrow-up':''" :style="{marginTop:'105px'}"
                                @click="moreInfo(item, index)"/>
                    </van-col>
                  </van-row>
                </div>
              </el-timeline-item>
              <el-timeline-item v-if="item.status!='02'" v-for="(item,index) in list" placement="top"
                                :key="index"
                                :timestamp=item.creationTime
                                :color="item.status=='01'?'red':item.status=='02'?'#318EF5':'#0bbd87'"
                                :style="{marginBottom: index==list.length-1 ? '45px' : '0px'}">
                <div>
                  <van-row>
                    <van-col offset="4" span="15">
                      <p :style="{color:item.status=='01'?'red':item.status=='02'?'#318EF5':item.status=='00'?'darksalmon':'#0bbd87'}">
                        状态：{{
                          item.status == '03' ? '已解决' : item.status == '01' ? '持续跟进' : item.status == '00' ? '新增' : '审核中'
                        }}</p>
                    </van-col>
                    <van-col offset="1" span="4">
                      <van-button v-if="item.status=='03'" icon="success" type="primary"
                                  @click="submit(item,index,'00')"/>
                      <van-button v-if="item.status=='02'" icon="good-job-o" type="info"
                                  @click="submit(item,index,'00')"/>
                      <van-button v-if="item.status=='01'" icon="clock-o" type="danger"
                                  @click="submit(item,index,'00')"/>
                      <van-button v-if="item.status=='00'" icon="edit" type="default" @click="submit(item,index,'00')"
                                  style="background-color: darksalmon"/>
                    </van-col>
                  </van-row>
                  <van-row>
                  <van-col span="20">
                    <van-field type="textarea" readonly label="解决方案：" rows="5" v-model="item.scheme"></van-field>
                  </van-col>
                  <van-col offset="1" span="3">
                    <van-icon :name="item.moreBtn?'arrow-down':''" :style="{marginTop:'105px'}"
                              @click="moreInfo(item, index)"/>
                  </van-col>
                  </van-row>
                  <van-field v-if="!item.moreBtn" type="textarea" readonly label="最新成果：" rows="5"
                             v-model="item.newResult"></van-field>
                                    <van-row v-if="!item.moreBtn">
                                      <van-col span="20">
                                        <van-field type="textarea" readonly label="领导留言：" rows="3"
                                                   v-model="item.official"></van-field>
                                      </van-col>
                                      <van-col offset="1" span="3">
                                        <van-icon :name="!item.moreBtn?'arrow-up':''" :style="{marginTop:'105px'}"
                                                  @click="moreInfo(item, index)"/>
                                      </van-col>
                                    </van-row>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </van-col>
      </van-row>
      <van-dialog
          v-model="show"
          title="持续跟进-通过"
          show-cancel-button
          :beforeClose="chargeBtn"
          confirm-button-text='通过'
          cancel-button-text='持续跟进'
      >
        <!--输入框-->
        <van-field
            v-model="official"
            rows="2"
            autosize
            label="领导评价"
            type="textarea"
            placeholder="请输入留言"
        />
      </van-dialog>
      <!-- 添加按钮-->
      <div class="add">
        <van-button class="add_button" color="#FD843E" icon="plus" @click="toAdd()"/>
      </div>
    </div>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import {Toast} from "vant";
import Add from '@/components/Add';
import {Dialog} from 'vant';

export default {
  name: "RemainProblermsDetail",
  components: {CrmTabBar, Add},
  data() {
    return {
      official: '',
      show: false,
      btn: false,
      theFocus: false,
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: false,
      reverse: true,
      list: [],
      list1: [],
      customerName: '',
      problerms: '',
      titleName: '',
      fzrName: '',
      userId: this.$store.getters.userId,
      id: '',
      scheme: '',
      newResult: '',
      createdPeople: '',
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight
    this.init();
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/problerm/getProblermsListDetailByCustomer.json',
          {
            crmCustomerid: this.$route.params.id,
            titleId: this.$route.params.title,
            createdPeople: this.$store.getters.userId === '05001' || this.$store.getters.userId === '04002' || this.$store.getters.userId === '19060' ? '' : this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.list = res.data
            console.log(this.list)
            this.customerName = this.list[0].customerName
            this.problerms = this.list[0].problerms
            this.titleName = this.list[0].titleName
            this.fzrName = this.list[0].createdName
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].status == '00' || this.list[i].status == '02' || this.list[i].status == '03') {
                this.btn = true;
              }
              this.list[i].moreBtn = true
            }
            Toast.success('加载成功');
          } else {
            Toast.success('暂无数据');
          }
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    toAdd() {
      if (this.btn) {
        this.$message.warning('该问题状态不允许新增方案！');
      } else {
        if (this.userId != this.list[0].createdPeople) {
          this.$message.warning('只有创建人允许新增方案！');
        } else {
          this.$router.push({path: "/RemainProblermsAdd/" + this.$route.params.title + "/" + this.$route.params.id});
        }
      }
    },
    submit(item, index, tag) {
      switch (item.status) {
        case '03':
          this.$message.success('该问题已解决！');
          break
        case '02':
          if (this.userId != '05001' && this.userId != '19060') {
            // if (this.userId == '19060') {
            this.$message.warning('您不具备审核的权限！');
            console.log(this.official)
          } else {
            this.id = item.id
            this.scheme = item.scheme
            this.createdPeople = item.createdPeople
            this.$ajax.post('/api/ajax/problerm/updateProblermScheme.json',
                {
                  official: this.official,
                  status: tag,
                  modifiedPeople: this.$store.getters.userId,
                  createdPeople: this.createdPeople,
                  createdName: this.fzrName,
                  customerName: this.customerName,
                  problerms: this.problerms,
                  scheme: this.scheme,
                  crmCustomerid: this.list[0].crmCustomerid,
                  titleId: this.list[0].titleId,
                  newResult: this.list[0].newResult,
                  id: this.id
                }).then(res => {
              if (res.status == 200) {
                this.$message.success('审核通过！');
                setTimeout(() => {
                  this.$router.replace({path: '/index'})
                }, 200);
              }
            }).catch(err => {
              console.log(err)
            })
            // this.show = true;
          }

          break
        case '01':
          this.$message.warning('该方案未通过！');
          break
        case '00':
          this.$router.push({path: "/RemainProblermsEdit/" + this.$route.params.title + "/" + item.id});
          break
      }
    },
    chargeBtn(action, done) {//确认or取消
      if (action === 'cancel') {//取消按钮
        this.$ajax.post('/api/ajax/problerm/updateProblermScheme.json',
            {
              official: this.official,
              status: '01',
              modifiedPeople: this.$store.getters.userId,
              createdPeople: this.createdPeople,
              createdName: this.fzrName,
              customerName: this.customerName,
              problerms: this.problerms,
              scheme: this.scheme,
              id: this.id
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('审核通过！');
            setTimeout(() => {
              this.$router.replace({path: '/index'})
            }, 200);
          }
        }).catch(err => {
          console.log(err)
        })
      } else if (action === 'confirm') {//确定按钮
        //向后端传值并关闭dialog弹出框
        this.show = false;
        this.$ajax.post('/api/ajax/problerm/updateProblermScheme.json',
            {
              official: this.official,
              status: '03',
              modifiedPeople: this.$store.getters.userId,
              createdPeople: this.createdPeople,
              createdName: this.fzrName,
              customerName: this.customerName,
              problerms: this.problerms,
              scheme: this.scheme,
              crmCustomerid: this.list[0].crmCustomerid,
              titleId: this.list[0].titleId,
              id: this.id
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('审核通过！');
            setTimeout(() => {
              this.$router.replace({path: '/index'})
            }, 200);
          }
        }).catch(err => {
          console.log(err)
        })
      }
      this.official = '';
    },
    moreInfo(item, index) {
      item.moreBtn = !item.moreBtn
      this.list.sort()
    },
  }
}
</script>

<style scoped>
.remain-problerms-detail {
  background-color: #FFFFFF;
}

.remain-problerms-detail .block {
  padding: 2px 2px 2px 2px;
}

.remain-problerms-detail .card {
  background: #318EF5;
  margin-bottom: 20px;
}

.remain-problerms-detail .title {
  padding: 10px 5px;
  font-weight: 400;
  font-size: 18px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.remain-problerms-detail .data {
  text-align: left;
  padding: 10px 5px;
  font-weight: 400;
  font-size: 14px;
  color: #EEEEEE;
  font-style: normal;
  letter-spacing: 0;
  line-height: 19px;
  text-decoration: none;
}

.remain-problerms-detail .add .add_button {
  position: fixed;
  top: 75%;
  left: 80%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #F98433;
}
</style>

<style>
.remain-problerms-detail .el-timeline-item__timestamp.is-top {
  margin-bottom: 9px;
  padding-top: 4px;
  margin-left: -210px;
  color: #007fff;
}

.remain-problerms-detail .el-card__body, .el-main {
  padding: 0px;
}

</style>